
















































































































import { Vue, Component } from "vue-property-decorator";
import DoctorAppointmentService from "../../service/DoctorAppointmentService";
import DoctorScheduleService from "@/doctor-app/service/DoctorScheduleService";
import { IDoctorSchedule } from "@/doctor-app/models/DoctorSchedule";
@Component
export default class TodayDashboard extends Vue {
  public doctorChamnerCount: any[] = [];
  public schedules: IDoctorSchedule[] = [];
  dailyOrWeekly = "daily";

  created() {
    DoctorAppointmentService.getChamberCountByAppointmentDate(new Date()).then((res) => (this.doctorChamnerCount = res.data || []));

    DoctorScheduleService.GetWeeklyScheduleList().then((res) => {
      if (res.data.result) {
        this.schedules = res.data.result;
        this.schedules = this.schedules.filter((s) => s.isBooked == false) || [];
        //alert(this.schedules.length)
        //console.log(this.schedules);
      }
    });
  }

  weeklyAppointment() {
    this.dailyOrWeekly = "weekly";
    DoctorAppointmentService.getChamberWeeklyCountByAppointmentDate(new Date()).then(
      (res) => (this.doctorChamnerCount = res.data || [])
    );

    DoctorScheduleService.GetWeeklyScheduleList().then((res) => {
      if (res.data.result) {
        this.schedules = res.data.result;
        this.schedules = this.schedules.filter((s) => s.isBooked == false) || [];
        //alert(this.schedules.length)
        // console.log(this.schedules);
      }
    });
  }

  dailyAppointment() {
    this.dailyOrWeekly = "daily";
    DoctorAppointmentService.getChamberCountByAppointmentDate(new Date()).then((res) => (this.doctorChamnerCount = res.data || []));

    DoctorScheduleService.GetWeeklyScheduleList().then((res) => {
      if (res.data.result) {
        this.schedules = res.data.result;
        this.schedules = this.schedules.filter((s) => s.isBooked == false) || [];
        //alert(this.schedules.length)
        // console.log(this.schedules);
      }
    });
  }
}
